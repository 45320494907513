<template>
	<v-col cols="12" sm="12" md="12" lg="11" xl="10" class="mx-auto px-6">
		<v-row align="center" dense>
			<v-col>
				<v-divider :class="cart_step >= 0 ? (cart_step === 0 ? 'elevation-2 secondary':'secondary'):''" class="rounded" style="border-width: 2px" />
			</v-col>
			<v-col cols="auto">
				<v-btn style="border-width: 2px; cursor: default" fab small :outlined="cart_step !== 0" color="secondary"><v-icon size="18">mdi-cart</v-icon></v-btn>
			</v-col>
			<v-col>
				<v-divider :class=" cart_step >= 1 ? cart_step === 1 ? 'elevation-2 secondary' : 'secondary':''" class="rounded" style="border-width: 2px" />
			</v-col>
			<v-col cols="auto">
				<v-btn  style="border-width: 2px; cursor: default" fab small :outlined="cart_step != 1" :color="cart_step >= 1 ? 'secondary':'grey lighten-1'"><v-icon size="18">mdi-credit-card</v-icon></v-btn>
			</v-col>
			<v-col>
				<v-divider :class=" cart_step >= 2 ? cart_step === 1 ? 'elevation-2 secondary' : 'secondary':''" class="rounded" style="border-width: 2px" />
			</v-col>
			<v-col cols="auto">
				<v-btn  style="border-width: 2px; cursor: default" fab small :outlined="cart_step != 2" :color="cart_step >= 2 ? 'secondary':'grey lighten-1'"><v-icon size="18">mdi-thumb-up</v-icon></v-btn>
			</v-col>
			<v-col>
				<v-divider :class=" cart_step >= 2 ? 'secondary elevation-2':''" class="rounded" style="border-width: 2px" />
			</v-col>
		</v-row>

		<v-row v-if="true" style="min-height: 300px" class="mt-4">
            <v-col cols="12" md="8" style="position: relative">
                <transition name="slide-fade">
                    <v-row v-show="cart_step === 0" :key="1" class="elevation-2 cell rounded-lg mx-0 pa-0 overflow-hidden" >
                        <v-col class="py-0">
                            <v-row class="text-center label--text font-weight-bold my-2 sub-title-2">
                                <v-col>{{ $tc('global.name.name', 1) }}</v-col>
                                <v-col>{{ $tc('global.product.price', 1) }}</v-col>
                                <v-col>{{ $tc('global.product.quantity', 1) }}</v-col>
                                <v-col>{{ $tc('global.product.subtotal', 1) }}</v-col>
                                <v-col>{{ $tc('global.name.extension', 2) }}</v-col>
                            </v-row>
                            <template v-for="(product,i) in cart_products">
                                <v-row :key="i" align='center' :class="i%2==0 ? 'cell2':''" class="text-center paragraph">
                                    <v-col>{{ product.product.display_name}}</v-col>
                                    <v-col v-html="$options.filters.formatPrice(product.product.price, '€')"></v-col>
                                    <v-col>
                                        <v-row align="center" justify="center">
                                            x {{ product.count }}
                                        </v-row>
                                        
                                    </v-col>
                                    <v-col class="labelprimary--text font-weight-bold" v-html="$options.filters.formatPrice(product.count * product.product.price + totalExtensionProduct(product), '€')"></v-col>
                                    <v-col>
                                        <v-row justify="center" class="ml-2" align="center">
                                            <div class="mr-2">{{ extensionProductNumber(product) }}</div>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <template v-for="ext in product.extensions">
                                    <v-expand-transition :key="ext.id" v-if="ext.count > 0 && product.extensions">
                                        <v-row align='center' :class="[i % 2 === 0 ? 'cell2':'', ext.count === 0 ? 'label--text':'']" class="text-center caption-1">
                                            <v-col>{{ ext.extension.display_name}}</v-col>
                                            <v-col v-html="$options.filters.formatPrice(ext.extension.price, '€')"></v-col>
                                            <v-col>
                                                <v-row align="center" justify="center">
                                                    x {{ ext.count }}
                                                </v-row>
                                            </v-col>
                                            <v-col class="" v-html="$options.filters.formatPrice(ext.count * ext.extension.price, '€')"></v-col>
                                            <v-col></v-col>
                                        </v-row>
                                    </v-expand-transition>
                                </template>
                            </template>
                            <v-divider class="mt-3 mx-3" />
                            <v-row justify="end" align="center" class="px-4">
                                <v-col>
                                    <v-btn @click="$router.push('/marketplace/cart')" height="34" color="celldark" dark class="rounded-lg" small>
                                        <v-icon size="18" left>mdi-arrow-left</v-icon><div class="paragraph font-weight-bold">{{ $tc('navbar.Cart.cart', 1) }}</div>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="sub-title-2 font-weight-bold pt-5 pb-4">Total :</v-col>
                                <v-col cols="auto" class="title-2 font-weight-bold labelprimary--text" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </transition>
				 <transition name="slide-fade">
                    <v-row v-if="cart_step === 1 && (contact_id !== null && contact_id !== undefined)" :key="1" class="elevation-2 cell rounded-lg mx-0 px-5 overflow-hidden">
                        <v-col>
                            <v-row>
								<v-col cols="12" class="pb-0 sub-title-2 font-weight-bold">{{ $tc('Cart.check_infos', 1) }}</v-col>
                                <v-col cols="12" class="pb-0">
                                    <v-divider />
                                </v-col>
                                <v-col cols="12" v-if="account_infos" >
                                    <v-row class="sub-title-2">
                                        <v-col>{{ $tc('Cart.account_infos', 1) }}</v-col>
                                    </v-row>
                                    <v-row dense v-for="(info, key, i) in account_infos" :key="i">
                                        <v-col class="label--text font-weight-bold paragraph" style="max-width: 240px">{{ key }} :</v-col>
                                        <v-col class="paragraph">{{ info }}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" v-if="contact_infos">
                                    <v-row class="sub-title-2">
                                        <v-col>{{ $tc('Cart.contact_infos', 1) }}</v-col>
                                    </v-row>
                                    <v-row dense v-for="(info, key, j) in contact_infos" :key="j">
                                        <v-col class="label--text font-weight-bold paragraph" style="max-width: 240px">{{ key }} :</v-col>
                                        <v-col class="paragraph">{{ info }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
							<v-row class="mx-0 my-3" align="center">
                                <v-col class="pa-0" cols="auto">
                                    <v-btn @click="cart_step --" height="35" color="celldark" dark class="rounded-lg" small block>
                                        <v-icon left size="18">mdi-arrow-left</v-icon><div class="paragraph font-weight-bold">{{ $tc('global.action.back', 1) }}</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </transition>

                <transition name="slide-fade">
                    <v-row v-show="cart_step === 2" :key="2" class="elevation-2 cell rounded-lg mx-0 pa-0 overflow-hidden" >
                        <v-col class="pt-0 pb-1">
                            <v-row class="title-1 text-center label--text font-weight-bold my-2">
                                <v-col >{{ $tc('Cart.thank_you', 1) }}</v-col>
                            </v-row>
                            <v-row class="text-center label--text font-weight-bold my-2 sub-title-1">
                                <v-col>{{ $tc('Cart.purchase_message', 1) }}</v-col>
                            </v-row>
                            <v-row class="mx-0">
                                <v-col>
                                    <v-btn block color="primary" class="rounded-lg text-center" @click="$router.push('/marketplace')">
                                        <v-icon left size="18">mdi-home</v-icon>
                                        <div class="font-weight-bold paragraph">{{ $tc('Cart.btn_market', 1) }}</div>
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn color="celldark" dark block class="rounded-lg text-center" @click="$router.push('/dashboard')">
                                        <v-icon left size="18">mdi-view-dashboard</v-icon>
                                        <div class="paragraph font-weight-bold">{{ $tc('global.name.dashboard', 1) }}</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </transition>                
            </v-col>

            <v-col cols="12" md="4" style="position: relative">
                <transition name="slide-y-fade">
                    <v-row class="elevation-2 cell rounded-lg mx-0 px-3 pb-3" dense v-if="cart_step === 0">
                        <v-col>
                            <v-row align="center" class="mx-0 sub-title-2">
                                <v-col>{{ $tc('Cart.promo', 1) }}</v-col>
                                <v-col>
                                    <TextField :placeholder="$tc('global.action.apply', 1)" backgroundColor="cell2" />
                                </v-col>
                            </v-row>
                            <v-divider class="mx-4" />
                            <v-row class="mt-2 mx-0 sub-title-2">
                                <v-col class="font-weight-bold">{{ $tc('Cart.price_details', 1) }}</v-col>
                            </v-row>
                            <v-row class="mx-0 paragraph" align="center">
                                <v-col>{{ $tc('global.product.subtotal', 1) }} :</v-col>
                                <v-col><div class="text-no-wrap" style="font-weight: 500" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></div></v-col>
                            </v-row>
                            <v-row class="mx-0 paragraph" align="center">
                                <v-col>{{ $tc('global.product.tax', 1) }} :</v-col>
                                <v-col style="font-weight: 500">{{ $tc('Cart.none', 1) }}</v-col>
                            </v-row>
                            <v-divider class="mx-4" />
                            <v-row class="mx-0" align="center">
                                <v-col class="paragraph">Total :</v-col>
                                <v-col>
                                    <div class="title-2 font-weight-bold labelprimary--text text-no-wrap" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></div>
                                </v-col>
                            </v-row>
                            <v-btn :disabled="cart_products.length === 0 || account_infos === null || contact_infos === null" color="primary" block class="mt-2 rounded-lg" @click="cart_step++">
                                <div class="paragraph font-weight-bold">{{ $tc('global.action.validate', 1) }}</div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </transition>

                <transition name="slide-y-fade">
                    <v-row class="elevation-2 cell rounded-lg mx-0 px-4 pb-4" dense v-if="cart_step === 1 ">
                        <v-col>
                            <v-row class="mt-2 mx-0">
                                <v-col class="sub-title-2 font-weight-bold px-0">{{ $tc('Cart.beneficiary', 1) }}</v-col>
                            </v-row>
                            <v-divider class="mt-1 mb-2" />
                            <v-row class="mx-0 paragraph" align="center">
                                <v-col class="px-0">{{ $tc('Cart.beneficiary_message', 1) }}</v-col>
                            </v-row>
                            <v-row class="mx-0 pt-2">
                                <Actions
                                        layout="cart"
                                        :actions="actions"
                                        :path="'cart'"
                                        checkout
                                        @execAction="execBuy"
                                        medium
                                        :methods="methods"
                                        :pevents="events"
                                />
                            </v-row>
                        </v-col>
                    </v-row>
                </transition>

                <transition name="slide-y-fade">
                    <v-row class="elevation-2 cell rounded-lg mx-0 px-4 pb-4 paragraph" dense v-if="cart_step === 2">
                        <v-col>
                            <v-row class="mt-2 mx-0">
                                <v-col class="font-weight-bold sub-title-2">{{ $tc('Cart.purchase_details', 1) }}</v-col>
                            </v-row>
                             <v-row class="mx-0" align="center">
                                <v-col>{{ $tc('Cart.purchase', 1) }} :</v-col>
                                <v-col class="font-weight-bold sub-title-1" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></v-col>
                            </v-row>
                            <v-row class="mx-0" align="center">
                                <v-col>{{ $tc('global.name.account', 1) }} :</v-col>
                                <v-col v-if="receiver_infos.display_name">{{ receiver_infos.display_name }}</v-col>
                                <v-col v-else>{{ account_infos.name }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </transition>
            </v-col>
        </v-row>

	</v-col>
</template>

<script>
	export default {
		name: "Checkout",
		components: {
			Actions: () => import("@/components/Actions.vue"),
            TextField: () => import('@/components/fields/TextField.vue')
		},
		data(){
			return {
				cart_step: 0,
				ready: false,
				actions: [],
                methods: [],
                events: null,
				account_infos: null,
				contact_infos: null,
				list_fields_account: ['id', 'name','shipping_street', 'shipping_city', 'shipping_zipcode', 'shipping_state', 'shipping_country'],
				list_fields_contact: ['display_name', 'email'],
				receiver_infos: {}
			}
		},
		computed: {
			getTotalCartPrice(){
				let total = 0

				this.cart_products.forEach(ele => {
					total += ele.count * (this.totalExtensionProduct(ele) + ele.product.price)
				})

				return total
			},
			contact_id(){
				return  this.$store.getters["auth/contact_id"]
			},
			account_id(){                
				return this.$store.getters["auth/account_id"]
			},
			cart_products(){
				return this.$store.getters['cart/products']
			}
        }, 
		methods: {
			execBuy(e){
				this.cart_step++

				this.receiver_infos = e
			},
			totalExtensionProduct(product){
				let res = 0

				if(product.extensions) {
					product.extensions.forEach(e => {
						res += e.count * e.extension.price
					})
				}

				return res
			},
			extensionProductNumber(product){
				let res = 0

				if(product.extensions) {
					product.extensions.forEach(e => {
						if(e.count > 0)
							res++
					})
				}

				return res
			},
			getActions(){
				this.$wsc.getOptions(this.$route.params, {}, 'cart', actions => {
					this.actions = actions.actions
                    this.methods = actions.methods
				},fail => {

				})
			},
			getInfos(array, route, id, callback){
				this.$wsc.getItem(route, id, infos => {
                    let keys = Object.keys(infos)
                    let object = {}
                    
					for (let i = 0; i < array.length; i++){
						for (let j = 0; j < keys.length; j++){
							if (keys[j].match(array[i]) !== null){
								this.$set(object, keys[j], infos[keys[j]])
							}
						}
                    }
                    
                    callback(object)
				}, fail => {
                    callback(null)
				})
    		}
		},
		created() {
            this.getActions()

            if(this.contact_id !== null && this.contact_id !== undefined){
                this.getInfos(this.list_fields_contact, 'contact', this.contact_id, (res) => {
                    this.contact_infos = res
                })
            }

            if(this.account_id !== null && this.account_id !== undefined){
                this.getInfos(this.list_fields_account, 'account', this.account_id, (res) => {
                    this.account_infos = res
                })
            }
    	},
        beforeRouteLeave(to, from, next){
		    if(this.cart_step === 2) {
                this.$store.commit('cart/CHANGE_PRODUCTS_VAL', [])
            }

            next()
        }
	}
</script>

<style scoped>

</style>